/* // .full-landing-image{
    //     width: 100%;
    //     height: 100vh;
    //     background: url('https://i.postimg.cc/J07vSC9q/hero-background.jpg') no-repeat center;
    //     background-size: cover;
    // }
    
    // .columnss {
    // 	margin: 15px 15px 0;
    // 	padding: 0;
    // } */
    /* .columnss:last-child {
        //padding-bottom: 60px;
    } */
    .columnss::after {
        content: '';
        clear: both;
        display: block;
    }
    .columnss div {
        /* position: relative;
        float: left;
        width: auto;
        height: auto; */
        /* //margin: 0 0 0 25px;
        //padding: 0; */
        position: absolute;

        @media (max-width: 500px) {
            position: relative;
        }
    }
    .columnss div:first-child {
        margin-left: 0;
    }
    .columnss div span {
        position: absolute;
        bottom: -20px;
        left: 0;
        z-index: -1;
        display: block;
        width: 300px;
        margin: 0;
        padding: 0;
        color: #444;
        font-size: 18px;
        text-decoration: none;
        text-align: center;
        -webkit-transition: .3s ease-in-out;
        transition: .3s ease-in-out;
        opacity: 0;
    }
    figure {
        /* width: 270px;
        height: 200px; */
        width: 510px;
        height: 450px;
        margin: 0;
        padding: 0;
        /* background: #fff; */
        background: transparent;
        overflow: hidden;
        transform: translateY(-225px);
        display: flex;
        justify-content: center;
        align-items: center;

        @media(max-width: 500px) {
            position: relative;
            transform: translateY(0);
        }
    }
    @media (max-width: 500px) {
        figure {
            height: 300px;
        }
    }
    @media (max-width: 1000px) {
        figure {
            position: relative !important;
            transform: translateY(0);
        }

         
        .columnss div {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    figure:hover+span {
        bottom: -36px;
        opacity: 1;
    }

    
    .rippleForMobile::before {
        -webkit-animation: circle .75s;
        animation: circle .75s;
        bottom: -36px;
        opacity: 1;
    }
    
    /* Circle */
    .hover15 figure {
        /* position: relative; */
        position: absolute;
    }
    .hover15 figure::before {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        display: block;
        content: '';
        width: 0;
        height: 0;
        background: rgba(255,255,255,.2);
        border-radius: 100%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        opacity: 0;
    }
    .hover15 figure:hover::before {
        -webkit-animation: circle .75s;
        animation: circle .75s;
    }
    @-webkit-keyframes circle {
        0% {
            opacity: 1;
        }
        40% {
            opacity: 1;
        }
        100% {
            width: 200%;
            height: 200%;
            opacity: 0;
        }
    }
    @keyframes circle {
        0% {
            opacity: 1;
        }
        40% {
            opacity: 1;
        }
        100% {
            width: 200%;
            height: 200%;
            opacity: 0;
        }
    }
    
    .dontshow
    {
        visibility: hidden;
        display: none !important;
    }
    
    @media all and (max-width:600px){
        .dontshow
        {
            position: absolute !important;
            top: 0 !important;
            visibility: hidden;
            display: none;
        }
    }

    .AO_LOGO_MEANING {
        max-width: 600px;

        /* max-width: 91.5%; */
    /* display: flex;
    justify-content: center; */
    /* margin: auto; */
}

    @media (max-width: 500px) {
        .AO_LOGO_MEANING {
      max-width: 400px;
        }
    }